import { animation, animate, style } from '@angular/animations';

export const collapseEnter = animation([
  style({ height: '0', overflow: 'hidden' }),
  animate('100ms', style({ height: '*' }))
]);
export const collapseLeave = animation([
  style({ height: '*', overflow: 'hidden' }),
  animate('100ms', style({ height: '0' }))
]);
