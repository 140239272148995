import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent {
  public challenge = content.home.challenge;
}
