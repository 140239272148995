import { Router, NavigationStart } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

export interface IseoData {
  description: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  public defaultTitle = 'IoTell';

  constructor(private meta: Meta, private title: Title, router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.reset();
    });
  }

  public noIndex() {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

  public reset() {
    this.title.setTitle(this.defaultTitle);
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=robots');
  }

  public set(seoData: IseoData) {
    this.title.setTitle(seoData.title);
    this.meta.updateTag({ name: 'description', content: seoData.description });
  }
}
