import { SeoService } from './../services/seo.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { Angulartics2 } from 'angulartics2';

function hashFunc(text: string) {
  let hash = 0;
  let chr;
  if (text.length === 0) {
    return hash;
  }
  for (let i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    // tslint:disable-next-line: no-bitwise
    hash = (hash << 5) - hash + chr;
    // tslint:disable-next-line: no-bitwise
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

const order = {
  content: [{ image: 'assets/img/iotell_logo.png' }, { text: 'Bestellbestätigung', fontSize: 30 }]
};

@Component({
  selector: 'app-onetapbuy-promo',
  templateUrl: './onetapbuy-promo.component.html',
  styleUrls: ['./onetapbuy-promo.component.scss']
})
export class OnetapbuyPromoComponent implements OnInit, OnDestroy {
  private paramSubscription: Subscription;
  public allreadyBought = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private angulartics: Angulartics2,
    private seo: SeoService
  ) {}

  public download() {
    if (this.allreadyBought === false) {
      saveAs('assets/Bestellbestaetigung.pdf', 'Bestellbestaetigung');
      localStorage.setItem('ats', '1');
      this.allreadyBought = true;
      this.angulartics.eventTrack.next({ action: 'order Wine', properties: { category: 'OneTapBuy' } });
    }
  }

  public ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

  public ngOnInit() {
    this.paramSubscription = this.route.params.subscribe(param => {
      if (!param || !param.id) {
        this.router.navigate(['/']);
      }
      if (hashFunc(param.id) !== 2030476624) {
        this.router.navigate(['/']);
      }

      if (localStorage.getItem('ats') === '1') {
        this.allreadyBought = true;
      }
    });

    this.seo.noIndex();
  }
}
