import { SeoService } from './../services/seo.service';
import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {
  public header = content.storiesHeader;
  public home = content.home;
  public seoData = content.sotriesSeo;

  constructor(private seo: SeoService) {}

  public ngOnInit() {
    this.seo.set({ title: this.seoData.title, description: this.seoData.description });
  }
}
