import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  public isCollapsed = true;
  public routerEvents: Subscription;

  constructor(public router: Router) {}

  public ngOnDestroy() {
    this.routerEvents.unsubscribe();
  }

  public ngOnInit() {
    const localThis = this;
    this.routerEvents = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        localThis.isCollapsed = true;
      }
    });
  }

  public toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
