import { Component, OnInit, Input } from '@angular/core';

export interface IPerson {
  email: string;
  fullName: string;
  id: string;
  portrait: string;
  shortText: string;
  tel: string;
}

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent {
  @Input() public person: IPerson;
}
