import { OnetapbuyPromoComponent } from './onetapbuy-promo/onetapbuy-promo.component';
import { StoriesComponent } from './stories/stories.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { PostComponent } from './news/post/post.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: '*',
    component: HomeComponent
  },
  {
    path: 'story',
    component: StoryComponent
  },
  {
    path: 'stories',
    component: StoriesComponent
  },
  {
    path: 'onetapbuy/products/:id',
    component: OnetapbuyPromoComponent
  },
  {
    path: 'stories/:id',
    component: StoryComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'news/:id',
    component: PostComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
