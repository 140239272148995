import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-whom',
  templateUrl: './whom.component.html',
  styleUrls: ['./whom.component.scss']
})
export class WhomComponent {
  public whom = content.home.whom;
}
