import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  public qrNumber: Observable<string>;
  constructor(public route: ActivatedRoute) {}

  public ngOnInit() {
    this.qrNumber = this.route.queryParamMap.pipe(map(params => params.get('qr')));
    this.qrNumber.subscribe(value => {
      console.log('value :', value);
    });
  }
}
