import { SeoService } from './../services/seo.service';
import { IHeader } from './../header/header.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { content } from '../content/content';

export interface IStoryStep {
  img: string;
  imgAlt: string;
  text: string;
  title: string;
  viewIoTell: string;
  viewService: string;
  viewTec?: string;
}

export interface IStory {
  description: string;
  header: IHeader;
  id: string;
  stories: IStoryStep[];
  storyNames: {
    IoTell: string;
    serviceName: string;
    tecName: string;
  };
}

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public story = content.stories[0];

  constructor(private route: ActivatedRoute, private router: Router, private seo: SeoService) {}

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    let story;
    this.subscription = this.route.params.subscribe(params => {
      if (params && params.id) {
        story = content.stories.find(oneStory => oneStory.id === params.id);
      }
    });

    if (!story) {
      return this.router.navigate(['/stories']);
    }

    this.story = story;

    this.seo.set({ title: this.story.header.title, description: this.story.description });
  }
}
