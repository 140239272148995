import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.scss']
})
export class WhyComponent {
  public why = content.home.why;
}
