import { Component, OnInit, Input } from '@angular/core';
import { IStoryStep } from '../story/story.component';
import { trigger, style, animate, transition, useAnimation } from '@angular/animations';
import { collapseEnter, collapseLeave } from '../animations/animation';
import { content } from '../content/content';

@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', useAnimation(collapseEnter)),
      transition(':leave', useAnimation(collapseLeave))
    ])
  ]
})
export class StoryCardComponent {
  @Input() public index;
  public ioTellTextVisible = false;
  @Input() public names;
  public serviceTextVisible = false;
  @Input() public story: IStoryStep;
  public tecTextVisible = false;
  @Input() public visible;

  public isMobileView() {
    if (window.innerWidth >= 992) {
      return false;
    }
    return true;
  }

  public isVisible(name) {
    if (!this.isMobileView()) {
      return true;
    }
    const visible = name === this.visible;
    return visible;
  }

  public setVisible(name) {
    if (name === this.visible) {
      this.visible = undefined;
      return;
    }
    this.visible = name;
  }
}
