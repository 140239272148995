import { SeoService } from './../services/seo.service';
import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contact = content.contact;

  constructor(private seo: SeoService) {}

  public ngOnInit() {
    this.seo.set({ title: this.contact.title, description: this.contact.description });
  }
}
