import { Component, OnInit, Input } from '@angular/core';

export interface IHeader {
  body?: string;
  btnLink?: string;
  btnText?: string;
  title: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() public header: IHeader;
}
