import { SeoService } from './../../services/seo.service';
import { IPerson } from './../../contact/team/person/person.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { content } from 'src/app/content/content';

export interface IPost {
  author: string;
  blocks: { subblocks: string[]; title: string }[];
  date: string;
  description: string;
  id: string;
  img: string;
  imgAlt: string;
  intro: string;
  order: number;
  script?: string;
  title: string;
}

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnDestroy {
  public author: IPerson;
  public post: IPost;
  public subscription: Subscription;
  constructor(private route: ActivatedRoute, private router: Router, private seo: SeoService) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      this.post = content.news.posts.find(post => post.id === params.id);
    });

    if (!this.post) {
      this.router.navigate(['/news']);
    }

    // get the author
    this.author = content.contact.team.find(person => person.id === this.post.author);

    // set the seo title and tags
    this.seo.set({ title: this.post.title, description: this.post.description });
  }
}
