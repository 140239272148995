import { Component, OnInit } from '@angular/core';
import { content } from 'src/app/content/content';

@Component({
  selector: 'app-adress',
  templateUrl: './adress.component.html',
  styleUrls: ['./adress.component.scss']
})
export class AdressComponent {
  public address = content.contact.address;
}
