import { Component, OnInit } from '@angular/core';
import { content } from 'src/app/content/content';

@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.scss']
})
export class HostComponent {
  public host = content.contact.host;
}
