import { SeoService } from './../services/seo.service';
import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  public news = content.news;

  constructor(private seo: SeoService) {}

  public ngOnInit(): void {
    this.seo.set({ title: this.news.title, description: this.news.description });
  }
}
