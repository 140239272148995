import { IStory } from './../story/story.component';
import { ICard } from '../card/card.component';
import { IStoryStep } from '../story/story.component';
import { IPerson } from '../contact/team/person/person.component';
import { IHeader } from '../header/header.component';
import { IPost } from '../news/post/post.component';

export const content = {
  home: {
    title: 'IoTell - Das Standard IoT Produkt',
    description: `IoT für KMU. Das Standardprodukt für IoT Anwendungen im Bereich Service und Handel. 
    Integriert in Ihr ERP - minimaler Aufwand. Zwischen Produkt und Business Software`,
    header: {
      title: 'IoTell zwischen Produkt und Prozess',
      btnText: 'Kontakt',
      btnLink: 'contact',
      body: `Das IoT Standardprodukt für KMU - verlinken Sie Produkt und Business Software`
    } as IHeader,
    storyShort: {
      storyCards: [
        {
          header: 'IoTell im Service',
          text: `Dank IoTell erkennen Sie die Notwendigkeit eines technischen Aussendienst-Einsatzes, bevor der Kunde genervt das
          Telefon zur Hand nimmt.`,
          btn: 'IoTell im Service',
          link: '/stories/iotell',
          img: 'assets/img/story_1.png',
          imgAlt: 'Hausbesitzer mit Swimmingpool verreist in die Ferien.'
        },
        {
          header: 'One Tap Buy B2C',
          text: `Dank IoTell können Ihre Kunden digital Bestellen - ohne Webshop`,
          btn: 'One Tap Buy',
          link: '/stories/onetapbuy',
          img: 'assets/img/story_onetapbuy_1.png',
          imgAlt: 'Nachschub wird geliefert.'
        },
        {
          header: 'One Tap Buy B2B',
          text: `Ihre Geschäftskunden können mit One Tap Buy beqeum digital bei Ihnen bestellen.`,
          btn: 'One Tap Buy B2B',
          link: '/stories/onetapbuyb2b',
          img: 'assets/img/story_onetapbuy-b2b_2.png',
          imgAlt: 'QR Code scannen'
        }
      ] as ICard[]
    },
    why: {
      title: 'Warum',
      text: `Das Bedürfnis nach IoT-Technologie, respektive nach einem Standardprodukt 
      im IoT-Bereich für KMU wuchs in den letzten Jahren stark an. Um diesen Firmen den 
      Zugang zu dieser Technologie zu ermöglichen wurde IoTell ins Leben gerufen.`,
      reasons: [
        { text: 'Verbindung der physischen mit der digitalen Welt', icon: 'fas fa-cloud' },
        { text: 'Businessprozess durchgängig machen dank Digitalisierung', icon: 'fas fa-link' },
        { text: 'Stand und Verfügbarkeit der Technologie im Bereich IoT', icon: 'fas fa-wifi' },
        { text: 'KMU erschwinglich digitalisieren', icon: 'fas fa-dollar-sign' },
        { text: 'Kundenbindung stärken', icon: 'fas fa-handshake' },
        { text: 'Moderne Web Technologie nutzen', icon: 'fab fa-angular' },
        {
          text: 'KMU befähigen grossen und bekannten Firmen auf Augenhöhe zu begegnen',
          icon: 'fab fa-facebook-square'
        },
        { text: 'Datenschutz durch Transparenz erhöhen', icon: 'fas fa-user-shield' }
      ]
    },
    whom: {
      title: 'Für wen',
      text: '',
      tenants: [
        {
          text: `KMU, welche technischen Service für Geräte bei ihren Kunden anbieten`,
          icon: 'fas fa-user',
          iconColor: undefined
        },
        {
          text: `KMU, welche Verbrauchsmaterial ihren Kunden verkaufen`,
          icon: 'fas fa-user',
          iconColor: undefined
        },
        {
          text: `KMU, welche Verbrauchsmaterialien regelmässig bestellen`,
          icon: 'fas fa-user',
          iconColor: undefined
        },
        {
          text: `KMU, welche interne Prozesse automatisieren wollen`,
          icon: 'fas fa-user',
          iconColor: undefined
        }
      ]
    },
    challenge: {
      title: 'Herausforderung',
      intro: `Um ein Gerät oder <b>Produkt</b> sinnvoll mit einer Business Software zu verknüpfen, 
      müssen <b>Messdaten</b> und <b>Prozesse</b> und vor allem deren Kombination betrachtet werden.`,
      explanation: `Viele schweizer KMU setzen schon heute leistungsfähige Software (ERP) zur Abwicklung ihrer Prozess ein.
      Messdaten werden aber eher weniger erhoben, und sofern doch, ist deren Übermittlung, Interpretation und Integration in den
      Businessprozess sehr <b>aufwändig</b>. Die Gründe dafür liegen nicht an fehlender Technik, sondern an der 
      <b>Interdisziplinarität</b> in der Umsetzung.`,
      questions: [
        {
          text: `Welche Messwerte sind für den Businessprozess relevant? `,
          icon: 'fas fa-plus-circle',
          iconColor: 'text-info'
        },
        { text: `Wie können diese erhoben werden?`, icon: 'fas fa-check-circle', iconColor: 'text-success' },
        { text: `Wo und wie werden die Messdaten verdichtet?`, icon: 'fas fa-check-circle', iconColor: 'text-success' },
        { text: `Wie werden die Daten übermittelt?`, icon: 'fas fa-check-circle', iconColor: 'text-success' },
        {
          text: `Wie sieht es mit der Datensicherheit und Datenschutz aus?`,
          icon: 'fas fa-check-circle',
          iconColor: 'text-success'
        },
        { text: `Wo werden die Daten aufbewahrt?`, icon: 'fas fa-check-circle', iconColor: 'text-success' },
        { text: `Wie können die Daten interpretiert werden?`, icon: 'fas fa-plus-circle', iconColor: 'text-info' },
        {
          text: `Wie funktioniert die Interaktion mit einem ERP?`,
          icon: 'fas fa-check-circle',
          iconColor: 'text-success'
        },
        { text: `Wie sieht der Businessprozess aus?`, icon: 'fas fa-plus-circle', iconColor: 'text-info' }
      ],
      solution: `IoTell strebt an, viele dieser Fragen mit einem <b>Standardprodukt</b> für verschiedene Anwendugsszenarien zu beantworten.
      Dadurch soll die Nutzung dieser Technologie für <b>KMU</b> erreichbar sein.`
    }
  },
  storiesHeader: { title: 'IoT Konkret', body: `Anwendungsfälle & Stories von IoTell` } as IHeader,
  sotriesSeo: {
    title: 'IoTell Anwendungsfälle & Stories',
    description: `Anwendungsfälle von IoTell - das Standard IoT Produkt für KMU. Verbinden Sie Ihre Produkte mit Ihren Prozessen. 
    Digitalisierter Serice. Digitalisierter Verkauf.`
  },
  stories: [
    {
      id: 'iotell',
      header: {
        title: 'Technischer Service voll digitalisiert',
        body: 'IoTell im Service für Schwimmbäder'
      },
      description: `Erkenen Sie einen Servicebedarf im Voraus. Messwerte dank IoT erfassen, auswerten und 
      Servicaufträge automitisert im ERP anlegen.`,
      storyNames: {
        serviceName: 'Müller Service AG',
        IoTell: 'IoTell',
        tecName: 'Technik'
      },
      stories: [
        {
          title: 'Das Problem entsteht',
          img: 'assets/img/story_1.png',
          imgAlt: 'Kunde bestellt Wein mittels qr-code',
          text: `Thomas ist stolzer Besitzer eines Pools der Müller Service AG. 
          Zur Zeit ist Thomas gerade auf Reisen. Währenddessen beginnt sein Pool
          seltsam auszusehen und zu riechen, offenbar müsste hier ein Techniker zum Rechten sehen.`,
          viewService: `Die Müller Service AG ist spezialisiert auf Pools und geniesst Thomas' volles Vertrauen, 
          darum hat Thomas mit ihnen einen Servicevertrag abgeschlossen, der im ERP der Müller Service AG hinterlegt ist. 
          Dort finden sich auch sämtliche Informationen zu den installierten Geräten des Pools. 
          Damit am Pool Messdaten erfasst werden können, wurde das IoTell Edge Device eingebaut.`,
          viewIoTell: `Das Edge Device erfasst und verdichtet Messdaten (Temperatur, PH-Wert, etc.). 
          Diese werden der IoTell Plattform übermittelt.`,
          viewTec: ``
        },
        {
          title: 'IoTell meldet das Problem',
          img: 'assets/img/story_2.png',
          imgAlt: 'Wasser im Pool wird schlecht - IoTell informiert',
          text: `Obwohl Thomas auf seinem Smartphone eine Meldung von IoTell erhält, 
          kümmert ihn dies nur wenig - denn zeitgleich geht diese Nachricht auch
          an die Müller Service AG, deren Backoffice die Meldung verifiziert und diesen Servicefall einem Techniker zuweist.`,
          viewService: `Die IoTell Plattform eröffnet aufgrund des Alarms vom Pool automatisch den Servicefall im ERP der Müller 
          Service AG. Das Backoffice kann den Servicefall anhand der Messdaten verifizieren. Dazu können die Messwerte im Web dargestellt 
          und verglichen werden. Die Techniker-Einsätze werden wie gewohnt durch Unterstützung des ERPs koordiniert.`,
          viewIoTell: `Die Plattform löst regelbasiert einen Alarm aus. Eine Regel kann im einfachsten 
          Fall ein Schwellwert sein (z.B. pH-Wert zu tief). Dieser Alarm führt zum erwähnten Servicefall im ERP. 
          Damit dieser einem Pool eindeutig zugeordnet werden kann, muss die Plattform die ERPGerätenummer
          des Pools kennen. Auch Thomas kann den Alarm und die dazugehörigen Messdaten auf dem Smartphone kontrollieren.`,
          viewTec: ``
        },
        {
          title: 'Problembehebung',
          img: 'assets/img/story_3.png',
          imgAlt: 'Pool wurde durch Servicefirma repariert.',
          text: `Die Servicetechnikerin erkennt aufgrund des Messdaten-Verlaufs die Dringlichkeit, 
          meldet sich bei Thomas und macht sich auf den Weg zum Pool. Vor Ort helfen Langzeitdaten um das 
          Problem weiter zu analysieren und zu beheben.`,
          viewService: `Die Servicetechnikerin fixiert nach Rücksprache mit Thomas den Termin. 
          Vor Ort rapportiert sie die Arbeitszeit und das benötigte Material im ERP.`,
          viewIoTell: `Die Plattform bietet statistische Auswertungen und die Möglichkeit, 
          Messdaten mit historischen Daten zu vergleichen. Der Alarm wird nach dem Schliessen des Servicefalls zurück gesetzt.`,
          viewTec: ``
        }
      ]
    },
    {
      id: 'onetapbuy',
      header: { title: 'Mit einem Tap bestellt', body: 'IoTell One Tap Buy' },
      description: `Digitalisierter Verkauf. Schnell und effizient bestellen ohne Webshop. 
      Integriert in Ihr ERP und dadurch keinen Pflegeaufwand`,
      storyNames: {
        serviceName: 'Weinhandel Muster AG',
        IoTell: 'IoTell',
        tecName: 'Technik'
      },
      stories: [
        {
          title: 'Die Flasche ist leer',
          img: 'assets/img/story_onetapbuy_1.png',
          imgAlt: 'Die Weinflasche ist leer.',
          text: `Thomas geniesst mit seinen Freunden einen guten Tropfen.
          Leider war es die letzte Flasche. Wo nur hatte er diesen Wein
          gekauft? Zum Glück entdeckt Thomas auf der Flasche einen
          Aufkleber «Hier bestellen». Nichts wie los, QR-Code scannen und
          Thomas kann den feinen Wein mit «One Tap Buy» nachbestellen.`,
          viewService: `Die Weinhandel Muster AG ist eine kleine Weinhandlung mit
          persönlicher Betreuung. Der Fokus liegt bei ihnen nicht auf dem
          Handel im Web. Trotzdem ist ihnen wichtig, dass der Kunde einfach
          bestellen kann.`,
          viewIoTell: `IoTell erkennt aufgrund des QR-Codes den gewünschten Artikel
          und stellt auch die Identifikation vom Lieferanten sicher. Da die
          Weinhandel Muster AG über ein ERP verfügt, entfällt eine zusätzliche
          Pflege von Stammdaten vollständig.`,
          viewTec: ``
        },
        {
          title: 'Die Bestellung',
          img: 'assets/img/story_onetapbuy_2.png',
          imgAlt: 'QR Code auf Flasche scannen',
          text: `Nachdem Thomas die Bestellbestätigung erhalten hat, kann er sich
          auf die Lieferung freuen.`,
          viewService: `Die Weinhandel Muster AG sieht die Bestellung von Thomas
          direkt im ERP und kann diese wie gewohnt bestätigen und weiterverarbeiten.`,
          viewIoTell: `IoTell hat aufgrund der Bestellung von Thomas im ERP der Weinhandel
          Muster AG direkt eine Bestellung angelegt.`,
          viewTec: ``
        },
        {
          title: 'Die Lieferung',
          img: 'assets/img/story_onetapbuy_3.png',
          imgAlt: 'Die Bestellung wird geliefert.',
          text: `Thomas freut sich über die erhaltene Lieferung und plant das
          nächste Treffen mit seinen Freunden.`,
          viewService: ``,
          viewIoTell: ``,
          viewTec: ``
        }
      ]
    },
    {
      id: 'onetapbuyb2b',
      header: { title: 'Scan - Tap  - bestellt', body: 'One Tap Buy B2B' },
      description: `Digitalisierter Verkauf. Schnell udn effizient bestellen ohne Webshop. 
      Integriert in Ihr ERP und dadurch keinen Pflegeaufwand`,
      storyNames: {
        serviceName: 'Müller Handels AG',
        IoTell: 'IoTell',
        tecName: 'Technik'
      },
      stories: [
        {
          title: 'Material droht auszugehen',
          img: 'assets/img/story_onetapbuy-b2b_1.png',
          imgAlt: 'Fächer im Gestell mit Ware und QR Code',
          text: `Thomas arbeitet als Mechaniker in einer kleinen Produktionsfirma.
          Er ist verantwortlich, dass immer genügend Verbrauchsmaterial
          wie Schrauben und Muttern vorrätig ist. Solche C-Teile werden
          nicht lagergeführt, trotzdem müssen sie stets verfügbar sein.`,
          viewService: `Die Müller-Handels AG ist schon seit Jahren Lieferant für solche
          C-Teile von Thomas. Sie legt für jeden gelieferten Artikel einen
          QR-Code-Kleber bei, welcher Thomas am Behälter oder Gestell
          anbringen kann.`,
          viewIoTell: `IoTell erzeugt im Voraus für jeden Artikel einen QR-Code-Kleber,
          welcher der Erstlieferung beigelegt werden kann.`,
          viewTec: ``
        },
        {
          title: 'Die Bestellung',
          img: 'assets/img/story_onetapbuy-b2b_2.png',
          imgAlt: 'QR Code am Gestell scannen',
          text: `Sobald Thomas erkennt, dass gewisse Schrauben bestellt werden müssen,
          kann er den entsprechenden QR-Code mit seinem Mobile-Phone
          abscannen und mit einem Tap die gewünschte Menge bestätigen.`,
          viewService: `Die Müller-Handels AG sieht die Bestellung von Thomas direkt im ERP
          und kann diese wie gewohnt bestätigen und weiterverarbeiten.`,
          viewIoTell: `IoTell hat aufgrund der Bestellung von Thomas im ERP der Müller-Handels
          AG direkt eine Bestellung angelegt.`,
          viewTec: ``
        },
        {
          title: 'Die Lieferung',
          img: 'assets/img/story_onetapbuy-b2b_3.png',
          imgAlt: 'Die Bestellte Ware wird geliefert',
          text: `Thomas freut sich über die erhaltene Lieferung und kann in Ruhe
          weiter produzieren.`,
          viewService: ``,
          viewIoTell: ``,
          viewTec: ``
        }
      ]
    }
  ] as IStory[],
  contact: {
    header: {
      title: 'Wer ist IoTell'
    },
    title: 'Kontakt - IoTell',
    description: `IoTell - das IoT Stadard-Produkt für KMU. Kontaktieren Sie uns: info@iotell.ch`,
    team: [
      {
        fullName: 'Matthias Baumgartner',
        id: '1',
        portrait: 'assets/img/matthias.jpg',
        shortText: `ist Maschineningenieur mit grosser Erfahrung in der Software- und Prozess-implementieriung`,
        email: 'matthias.baumgartner@iotell.ch',
        tel: '+41 79 527 52 73'
      },
      {
        fullName: 'Andi Bolleter',
        id: '2',
        portrait: 'assets/img/andi.jpg',
        shortText: `ist Softwareentwickler mit langjähriger
      Tätigkeit im Bereich Business Software`,
        email: 'andi.bolleter@iotell.ch',
        tel: '+41 76 461 99 61'
      }
    ] as IPerson[],
    host: {
      text: `Das Bedürfnis nach IoT-Technologie, respektive nach einem Standardprodukt im IoT-Bereich für KMU 
      wuchs in den letzten Jahren im <a target="_blank" href="https://www.topsoft.ch"><b>topsoft</b></a> Umfeld stark an. 
      Um diesen Firmen den Zugang zu dieser Technologie zu ermöglichen wurde IoTell ins Leben gerufen.`
    },
    address: {
      mailing: `c/o schmid + siegenthaler consulting gmbh <br />
      Willistattstrasse 23 <br />
      6206 Neuenkirch <br />`,
      email: {
        address: 'info@iotell.ch',
        href: 'mailto:info@iotell.ch?subject=Kontaktanfrage'
      },
      mobile: {
        number: '+41 79 527 52 73',
        href: 'tel:+41795275273'
      }
    }
  },
  news: {
    title: 'Iotell News: IoT und Digitalisierung',
    description: 'Erfahren Sie das Neuste im Bereich IoT und Digitalisierung',
    header: {
      title: 'Wissen was kommt - IoT und Digitalisierung'
    },
    posts: [
      {
        author: '2',
        date: '2019-08-08',
        img: 'assets/img/javascript_code.jpg',
        imgAlt: 'toposft Fachmesse: Digitalisierung. Live. Konkret.',
        order: 4,
        title: 'JavaScript – endlich erwachsen?',
        id: 'javascript-endlich-erwachsen',
        description: `Wie sich JavaScript entwickelt hat? Ein kurze Analyse,
        weshalb JavaScript wichtig ist und auch in Zukunft relevant bleiben wird.`,
        intro: `«JavaScript ist keine richtige Programmiersprache» oder «Für kleine Projekte ganz ok, aber nichts
        für etwas Grosses», so der Sprache lange anhaftende Ruf. Die letzten Jahre hat sich die Sprache
        jedoch stark entwickelt. Sie ist erwachsener geworden und hat neue Einsatzgebiete erschlossen.
        Bekannt für den Einsatz in Webbrowsern, bietet sie sich inzwischen auch für die Programmierung von
        Applikationen auf Servern an. Grosse Namen wie Netflix und Uber verwenden JavaScript in diesem
        «Backend» Bereich. Warum entscheiden sich Weltkonzerne für JS?`,
        blocks: [
          {
            title: '',
            subblocks: [
              `JavaScript hat seine Wurzeln in Webbrowsern. Die Programmiersprache wurde 1995 von Netscape Communication ins Leben
              gerufen. Eingesetzt in ihrem damaligen Webbrowser Netscape Navigator, konnten dadurch neu Benutzerinteraktionen 
              ausgewertet und Inhalte dynamisch verändert werden. In anderen Worten: die Erweiterung sollte lebendigere
              Websites ermöglichen. Mit diesem Hintergrund wurde die Sprache daher auf Einfachheit und kleine Projekte ausgelegt.`
            ]
          },
          {
            title: 'Java – JavaScript',
            subblocks: [
              `Im Jahre 1996 entschied Netscape aus Marketing Gründen von der schnell an Popularität
              gewinnenden Sprache Java zu profitieren und benannte die Sprache von LiveScript in
              JavaScript um. Vom ähnlichen Namen abgesehen, unterscheiden sich die beiden Sprachen
              jedoch stark voneinander.`
            ]
          },
          {
            title: 'Eine echte Programmiersprache?',
            subblocks: [
              `Klassischerweise werden Programme in kompilierter Form ausgeliefert. Die Kompilierung
              wandelt den für Menschen einfach lesbaren Code in einen vom Computer gut lesbaren
              Code um. Dadurch läuft das Programm äusserst effizient ab. Dagegen ist JavaScript eine
              sogenannte Scriptsprache und wird nicht kompiliert. Damit eine JavaScript Applikation
              ausgeführt werden kann, muss ein Interpreter dazwischengeschaltet werden – sozusagen
              ein Übersetzer von JavaScript auf Maschinencode. Diese Übersetzung braucht Zeit und
              Ressourcen, weshalb Scriptsprachen per selangsamer laufen. Nebst Netscape hat 1996
              auch Microsoft einen JS Interpreter geschrieben und diesen im Internet Explorer eingesetzt.
              Leider interpretierten die beiden Browser JS nicht immer gleich. Als Folge liefen teils
              Homepages auf gewissen Browsern fehlerhaft oder gar nicht. Diese Problematik und die
              Tatsache, dass die Interpreter anfangs nicht so effizient «übersetzten», halfen dem Ruf von JS
              nicht. Zudem wurden die damals geschriebenen Interpreter jeweils im Browser ausgeführt,
              weshalb die Sprache auch bloss im Browser eingesetzt werden konnte.`
            ]
          },
          {
            title: 'Neue Einsatzgebiete',
            subblocks: [
              `2009 veröffentlichte Ryan Dahl mit Node.js, eine Programmier-Umgebung, welche neue
              Einsatzbereiche für JavaScript ermöglicht und auf allen gängigen Betriebssystemen läuft.
              In die Umgebung Node.js wurde der Interpreter von Google Chrome sowie ein Webserver
              integriert. Diese Kombination macht JavaScript zu einer starken Möglichkeit, über
              die bisherigen Webanwendungen hinaus auch Anwendungen im Backend zu entwickeln. In
              Node.js kann der JavaScript Code in «Module» unterteilt werden, um die Übersichtlichkeit zu
              verbessern, was speziell die Entwicklung von grösseren Applikationen unterstützt. Node.js
              wird im Rahmen eines Open Source Projekts entwickelt. Der integrierte Paket-Manager
              ermöglicht es, Pakete zu veröffentlichen, und anderen Entwicklern zur Verfügung zu stellen.
              Nicht zuletzt deswegen ist über die Jahre eine weltweite Community mit tausenden von
              Programmierern und einer riesigen Zahl von verfügbaren Programmteilen entstanden.`
            ]
          },
          {
            title: 'TypeScript',
            subblocks: [
              `Mit dem Hintergrund, dass JavaScript ursprünglich für einen relativ engen Einsatzbereich
              konzipiert worden ist, litt und leidet sie an gewissen strukturellen Mängeln. Diese
              wirken sich einerseits negativ auf die Schrei- bund Lesbarkeit aus, andererseits kann es
              immer wieder dazu kommen, dass der Code ein unerwartetes Verhalten an den Tag legt.
              Microsoft veröffentlichte 2012 die Programmiersprache TypeScript. Code in dieser Sprache
              wird in einem ersten Schritt in JavaScript kompiliert (transpiliert), was dann wiederum
              zur Laufzeit von einem Browser oder Node.js interpretiert wird.`,
              `Somit können in TypeScript sowohl fehlende Strukturen zur Verfügung gestellt als auch
              unerwartetes Verhalten minimiert werden. Am Ende entsteht wieder reines JavaScript mit
              all seinen Vorteilen.`
            ]
          },
          {
            title: 'Fazit',
            subblocks: [
              `Seit seinen Anfängen hat sich JavaScript enorm weiterentwickelt, Strukturen wurden
              verbessert, der Einsatz im Backend ermöglicht und die Community besser eingebunden. Das
              renommierte IT News Portal heise.de zeigt ein Ranking von RedMonk (https://heise.de/4475444), 
              worin JS gar auf Platz 1 der weltweit populärsten Programmiersprachen rangiert.
              Dies alles sind deutliche Zeichen einer erwachsenen Programmiersprache.`,
              `Wir bei IoTell haben uns ebenfalls von den vielen Vorteilen der Sprache überzeugen lassen.
              Unser zusätzliches Bedürfnis nach gut strukturiertem und lesbarem Code haben uns
              zu TypeScript geführt. Unsere Produkte im Bereich IoT verlangen nach einer Webanwendung
              für den User, einer Software welche Sensordaten aufbereitet, einem Backend und nicht
              zuletzt Schnittstellen ins ERP. All diese Komponenten in derselben Sprache entwickeln zu
              können, erhöht die Produktivität und spart Kosten. Dank dieser Durchgängigkeit können
              gesammelte Erfahrungen einfach untereinander ausgetauscht werden. Wir sind überzeugt,
              dass diese Strategie die Qualität der Software
              steigert.`
            ]
          }
        ]
      },
      {
        author: '1',
        date: '2019-06-20',
        img: 'assets/img/topsoft_logo.png',
        imgAlt: 'toposft Fachmesse: Digitalisierung. Live. Konkret.',
        order: 3,
        title: 'topsoft 2019 – wir sind dabei',
        id: 'topsoft-2019-wir-sind-dabei',
        description: `IoTell auf der topsoft Fachmesse 2019 - 28. / 29. August. 
        Das IoT Standard-Produkt für KMU. Erleben Sie IoTell im Service und OneTapBuy live und konkret.`,
        intro: `Die nächste Ausgabe der topsoft Fachmesse wird am 28./29. August in der Umweltarena in Spreitenbach 
        stattfinden. Wir freuen uns diese Gelegenheit nutzen zu können und Ihnen IoTell im Service und 
        OneTapBuy erstmals live präsentieren zu können.`,
        blocks: [
          {
            title: 'Digitalisierung. Live. Konkret.',
            subblocks: [
              `Die topsoft Fachmesse steht ganz unter dem Claim «Digitalisierung. Live. Konkret.». 
              IoTell selbst verbindet genau diese drei Aspekte zu einem Paket.`,
              `Die Digitalisierung wird das Business von vielen Unternehmen stark beeinflussen. 
              Es wird sich nicht nur die Art und Weise wie Business abgewickelt wird sich verändert, 
              sondern es werden sich komplett neuen Geschäftsfelder ergeben. Wir sind überzeugt, 
              dass es wichtig ist, diese Entwicklung nicht zu verpassen, sehen aber auch gleichzeitig 
              die Komplexität des Themas. Darauf begründet sich unsere Motivation eine Standardlösung 
              für Schweizer KMU anbieten zu können, welche die Brücke zwischen Produkt und Ihren gelebten Arbeitsabläufen schlägt.`,
              `Dies bedeutet konkret, dass wir Ihr Produkt mit Ihrem ERP verbinden. Mit IoTell können Messdaten live am Produkt 
              erfasst und ausgewertet werden. Ebenfalls werden die daraus resultierenden Ergebnisse direkt in Ihrem ERP als 
              Servicefälle, Aufgaben oder Bestellungen automatisch angelegt.`,
              `Warum dies auch für Sie spannend sein kann, erfahren Sie in unseren Stories.`
            ]
          },
          {
            title: 'Auf der Messe',
            subblocks: [
              `Erleben Sie IoTell im Service und OneTapBuy live und konkret am 28./29. August in der Umweltarena an der topsoft Fachmesse.`,
              `Gratis Tickets können Sie hier beziehen oder vereinbaren Sie noch heute einen Termin mit uns: m.baumgartner@iotell.ch`,
              `Wir freuen uns auf Ihren Besuch.`
            ]
          }
        ],
        script: `https://user.ticketpark.ch/embed/js/ticketing/show/B2AE6671-5C2D-4E06-A946-5CEE80387EE3`
      },
      {
        author: '2',
        date: '2019-06-02',
        img: 'assets/img/verzw_arb.png',
        imgAlt: 'Einen Webshop zu betreiben ist viel Arbeit.',
        order: 2,
        title: 'Das Ende vom Webshop?',
        id: 'das-ende-vom-webshop',
        description: `Ohne Webshop digitalisiert bestellen. Mit OneTapBuy E-Commerce voll nutzen. Kein extra
        Aufwand. Integration in Ihr ERP.`,
        intro: `Das Wachstum der Webshops in den letzten Jahren ist beeindruckend. Der Online-Versand-Handel
        Schweiz meldet im letzten Jahr einen Umsatz von 9.5 Mrd CHF. Dies entspricht einer Steigerung von
        10% gegenüber dem Vorjahr. Inzwischen hat fast jeder noch so kleine Händler einen Webshop. Damit
        buhlen aber auch immer mehr Anbieter online um die Aufmerksamkeit der Kunden. Der Aufwand,
        gefunden zu werden, steigt an. Das lässt sich mit einem neuen Ansatz in gewissen Fällen vermeiden.`,
        blocks: [
          {
            title: '',
            subblocks: [
              `Was macht online Shopping so attraktiv? Dies erklärt sich intuitiv am eigenen Verkaufsverhalten.
              Haben wir nicht alle schon bequem von Zuhause Gadgets im Web durchforstet?
              Statt physisch alle Läden abzulaufen, sind diese mit wenigen Klicks zugänglich. Dank Käuferbewertungen,
              «Bestseller» Highlighting oder gar personalisiertem Angebot ist das passende
              Produkt schnell gefunden. Nun noch ein Klick und die Bestellung ist erledigt.`,
              `Aber genau da liegt auch der Haken. Von wegen ein Klick: Einloggen, die cross-selling
              Angebote weg scrollen, die Liefervarianten durchklicken, sich für eine Bezahlmöglichkeit
              entscheiden, den Haken bei den Vertragsbedingungen setzen – Dank DSVGO muss ich
              den Haken für Newsletter nicht mehr entfernen, die Zusammenfassung bestätigen
              und endlich erscheint das ersehnte “Danke für ihren Einkauf”. Aber eigentlich hat die
              Klickorgie bereits zuvor begonnen, da war die Bestätigung der Cookies – DSVGO wiederum
              lässt grüssen – der up-selling-Dialog beim Ablegen des Produktes in den Warenkorb und
              seitenweise Scrollen.`
            ]
          },
          {
            title: 'Starker Wettbewerb – grosser Aufwand',
            subblocks: [
              `Der Online Markt ist stark preisgetrieben, die Margen sind gering. Dies zeigt sich deutlich
              bei der Unterhaltungselektronik. Das nahe Ausland ist hier nicht günstiger.`,
              `Aus Sicht der Händler ist der Betrieb eines Online Shops mit grossem Aufwand verbunden.
              Es fallen Investition in die Software und allenfalls Schnittstellen an. Noch mehr fallen
              aber die personellen Ressourcen ins Gewicht. Artikel wollen erfasst, kategorisiert und
              beschrieben werden. Zubehörartikel sollten verlinkt werden und alle Eigenschaften müssen
              funktional hinterlegt werden. Von SEO haben wir noch gar nicht gesprochen.`
            ]
          },
          {
            title: 'Neue Formen',
            subblocks: [
              `Es gibt heute ein paar Alternativen zu einem klassischen Webshop. Die bekanntesten sind
              grosse Plattformen wie Amazon oder eBay. Ein Händler muss sich in diesem Umfeld
              nicht mehr um Themen wie Infrastruktur oder Security kümmern. Innerhalb der Plattformen
              kann SEO aber nach wie vor nicht vernachlässigt werden.`,
              `Daneben gibt es weitere diskutierte Möglichkeiten wie die Verbindung von Webshops mit
              sozialen Netzwerken wie z.B. Facebook und Shopify. Auch ein Revival vom klassischen
              Telefon könnte man sich mit intelligenten Chatbots auf WhatsApp vorstellen. Bevor die
              Vor- und Nachteile dieser Kanäle diskutiert werden können, muss man sich überlegen,
              welche Funktionen ein Webshop innehat.`
            ]
          },
          {
            title: 'Funktionen eines Webshops',
            subblocks: [
              `Händler und ihre Produkte können dank einem Webshop gefunden werden. Durch
              Kategorien, Auflistungen und Suchfunktionen bringen sie ihr Sortiment dem Kunden näher.
              Mittels Bilder, Beschreibungen und Meinungen wird der Kunde vom Produkt überzeugt.
              Ob der Kunde in diesem Shop auch effektiv kauft, hängt noch von weiteren Faktoren ab
              wie Preis, Verfügbarkeit oder weiteren Leistungen. Durch das Abschliessen des Warenkorbs
              wird die Bestellung definitiv.`
            ]
          },
          {
            title: '«One Tap Buy»',
            subblocks: [
              `Es stellt sich die Frage, ob all diese Funktionen immer nötig sind. Oft ist ein Kunde mit einem
              Produkt zufrieden und möchte dieses erneut kaufen. In diesem Fall sind viele Funktionen
              überflüssig oder störend, der Prozess muss für den Kunden abgekürzt werden. Aus Kundenoptik
              könnte der Prozess lauten: Produkt scannen und bestellen. Für den Kunden entfällt damit die Suche nach dem ursprünglichen
              Händler und nach dem exakten Produkt. Der erneute Kaufentscheid fällt bei der Wiederbeschaffung
              vom physisch vorhandenen Produkt. In diesem Fall wird eine «Mobile Only»
              Lösung favorisiert, denn niemand möchte sich noch zusätzlich vor einen PC setzen. Der
              Kunde kann den Kaufentscheid beim Produkt umsetzen und die Bestellung in einem Schlag
              (one tap) definitiv machen.`,
              `Dieser Prozess hat auch für den Händler massgebliche Vorteile. Der Unterhalt eines
              klassischen Webshops und damit die intensive Pflege der Artikelinformationen entfallen
              grösstenteils. Der Aufwand für die Kundenakquisition ist kleiner, da der Kauf- und Händlerentscheid
              auf diesem Weg nicht hinterfragt werden.`
            ]
          },
          {
            title: 'Herausforderungen',
            subblocks: [
              `Um einen solchen Prozess durchgängig und damit effizient zu gestalten, ist eine Integration
              in ein bestehendes ERP sinnvoll. Die Authentifizierung und Identifizierung des Kunden
               muss funktionieren und muss vor dem Kauf stattfinden. Dies birgt immer das Risiko, dass
               ein Kunde den Kaufvorgang abbricht. Dies ist mit den heutigen Möglichkeiten von Login via
               Google respektive dem OAuth Standard lösbar. Wikipedia definiert OAuth (Open Authorization)
               als offenes Protokoll, das eine standardisierte, sichere API-Autorisierung für Desktop-,
               Web- und Mobile-Anwendungen erlaubt.`
            ]
          },
          {
            title: 'Webshop überflüssig?',
            subblocks: [
              `Nein! Der hier diskutierte Ansatz ist als eigenständiger Verkaufskanal zu verstehen. Weder
              bedingt noch konkurrenziert dieser Ansatz einen klassischen Webshop. Er kann für Firmen,
              welche heute noch keinen eigenen Webshop betreiben, einen idealen Einstieg in
              den digitalen Verkauf darstellen. Auch neben einem bestehenden Webshop ist ein paralleler
              One Tap Buy für den Kunden in vielen Fällen einfacher, schneller und direkter.`
            ]
          }
        ]
      },
      {
        author: '2',
        date: '2019-03-25',
        img: 'assets/img/iot_danger.jpg',
        imgAlt: 'IoT kann Gefahren für KMU bergen.',
        order: 1,
        title: 'Verlieren KMU ihre Kunden wegen IoT?',
        id: 'verlieren-kmu-ihre-kunden-wegen-iot',
        description: `Herausforderung von Internet of Things (IoT) als KMU begegnen und als Chance nutzen. Dank IoTell 
        Kundenbindung stärken und Serviceaufträge optimal einplanen.`,
        intro: `Viele geniessen den Komfort, welcher unser digitales Umfeld bietet. Das Restaurant ist bequem 
        über Siri gefunden und die Waschmaschine meldet, dass das Waschmittel zur Neige geht. Als KMU im 
        Servicebereich kann diese Entwicklung bedrohlich sein - oder neue Geschäftsmodelle mit stärkerer Kundenbindung ermöglichen.`,
        blocks: [
          {
            title: '',
            subblocks: [
              `In der Presse wird viel über «Internet of Things», dessen Möglichkeiten und Risiken geschrieben. Oft sind die 
              Anwendungsbeispiele aber recht abenteuerlich oder gar nicht vorhanden. So erinnere ich mich an einen Turbinenhersteller 
              mit dessen Turbine sogar gesprochen werden konnte. Wenn dieses Bild mit der Wartung einer heute üblichen Heizung verglichen 
              wird, öffnet sich eine Kluft zwischen dem theoretisch Möglichen und dem was heute erfolgreich eingesetzt wird. Dies schürt
               die Vermutung, dass im Fall der Turbine lediglich eine Marketingidee dahintersteckt.`,
              `Daneben gibt es auch Cases, welche in der Praxis einen echten Nutzen erzeugen. Diese sind z.B. daran erkennbar, 
               dass sie neue Geschäftsmodelle ermöglichen. Eines der bekannteren Beispiele ist von Miele. Deren Waschmaschinen 
               erkennen selbständig, dass der Spülmittelvorrat zur Neige geht und übermitteln den Kaufvorschlag via App dem Endanwender.`
            ]
          },
          {
            title: 'Wo das Risiko besteht',
            subblocks: [
              `In der Schweiz gibt es viele Firmen, welche eine Rolle zwischen Hersteller und Endanwender einnehmen. 
              Stellen wir uns die fiktive «Müller Service AG» vor. Diese stellt Geräte und Komponenten von verschiedenen 
              Lieferanten zusammen, verkauft und installiert sie bei ihren Kunden. Der Service der installierten Geräte ist 
              ein einträgliches Geschäft. Das kann sich aber dramatisch ändern, wenn Geschäftsmodelle, wie jenes von Miele 
              zur Regel werden. Denn sobald die Gerätehersteller selbst in der Lage sind, Daten direkt beim Endanwender zu 
              erfassen und auszuwerten, werden sie auch in der Lage sein, Servicefälle eigenständig zu erkennen. Hat die Müller 
              Service AG dann nichts mehr zu tun? Allenfalls schon noch, sofern der Hersteller die Servicearbeiten nicht selbst 
              durchführt, sondern an Müller delegiert.  Ob sich diese Aufträge für die Müller Service AG dann noch rechnen werden, 
              ist allerdings fraglich.`
            ]
          },
          {
            title: 'Die Antwort',
            subblocks: [
              `Ist dies alles Angstmacherei? Nein, leider nicht – und – nein, zum Glück nicht. Was mit IoT gemacht werden kann, 
              birgt für KMU die Gefahr im Serviceumfeld Kunden zu verlieren oder in ungewollte Abhängigkeiten zu geraten. 
              Demgegenüber eröffnet der IoT-Ansatz aber auch kleineren Unternehmen die Chance, Kundenbindungen zu stärken.`,
              `Die Müller Service AG hat gegenüber den Herstellern aktuell noch zwei entscheidende Vorteile: Sie geniesst das 
              Vertrauen ihrer Kunden und hat vertiefte Kenntnisse über deren Installationen. Die «Müllers» sind nun aber gefordert, 
              diese Vorteile zu bewahren und in Sachen IoT selbst aktiv zu werden.`
            ]
          },
          {
            title: 'Die Herausforderung',
            subblocks: [
              `Damit die Firma Müller Service AG IoT erfolgreich einsetzen kann, sind zwei Bereiche und deren Kombination relevant: `,
              `<ul>
                <li><b>Messdaten</b>: Messdaten erfassen, übermitteln und interpretieren</li>
                <li><b>Serviceprozess</b>: Servicefall effizient und kundennah lösen</li>
              </ul>
              `,
              `Viele moderne ERP-Systeme sind im Handling von Servicefällen bereits sehr ausgereift. Mit ihrer Hilfe können 
              Informationen von Kundeninstallationen gepflegt werden, es können Techniker disponiert und den Servicefällen zugeordnet 
              werden, Leistungen lassen sich erfassen und verrechnen. Für den Erfolg im Servicegeschäft ist ein ERP daher unerlässlich. 
              Im Bereich der Messdaten hingegen, können und sollen ERP-Systeme keine wesentliche Rolle spielen. Die grösste 
              Herausforderung liegt allerdings in der Integration von Messdaten und Serviceprozess. Es stellen sich neben der 
              Verfügbarkeit von Hard- und Software viele Fragen:`,
              `Welche Messwerte sind für den Serviceprozess relevant? Wie können diese erhoben werden? Wo und wie werden die Messdaten 
              verdichtet? Wie werden die Daten übermittelt? Wie sieht es mit der Datensicherheit und Datenschutz aus? Wo werden die Daten 
              aufbewahrt? Wie können die Daten interpretiert werden? Wie funktioniert die Interaktion mit einem ERP? Wie sieht der 
              Serviceprozess aus?`,
              `Die Interdisziplinarität von Maschinenbau, Sensorik, Elektrotechnik, Softwareentwicklung, Datenbankengineering, 
              Betriebswirtschaft, Prozessoptimierung, etc. stellt eine hohe Hürde für kleinere und mittlere Unternehmungen dar.`
            ]
          },
          {
            title: 'IoTell',
            subblocks: [
              `Mit der Erkenntnis, dass diese Fragestellungen in vielen Projekten gleiche oder ähnliche Lösungsansätze hervorbringen, 
              ist die Idee einer Standardlösung entstanden. IoTell wurde letztes Jahr lanciert, um ein «IoT-Standardprodukt» zwischen 
              Kundeninstallationen und ERP zu entwickeln. `,
              `Der Fokus von IoTell wurde dabei auf folgende Punkte gelegt: `,
              `<ul>
                <li><a href="story">Serviceprozess mittels Messdaten optimieren</a></li>
                <li>Nachbestellen von Verbrauchsmaterial automatisieren</li>
                <li>Endanwender durch App informieren und Nachbestellungen ermöglichen</li>
                <li>Firmeninterne Prozesse digital anstossen</li>
              </ul>
                `,
              `Die vier Beispiele zeigen, dass IoTell nicht nur für Serviceprozesse interessant ist, sondern übertragbar ist auf 
                andere Gebiete mit sehr ähnlichen Prozessen. Vielleicht lassen sich damit Aufgabenstellungen in Ihrem Tätigkeitsgebiet 
                lösen? Lassen Sie sich inspirieren – bei Interesse freuen wir uns auf ein Gespräch mit Ihnen über die vielfältigen 
                Zukunftsszenarien. `
            ]
          }
        ]
      }
    ] as IPost[]
  }
};
