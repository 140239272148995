import { Component, Input } from '@angular/core';

export interface ICard {
  btn?: string;
  header?: string;
  img: string;
  imgAlt: string;
  link?: string;
  text?: string;
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() public card: ICard;
}
