import { content } from './../../content/content';
import { Component, Input, OnInit } from '@angular/core';
import { IPost } from '../post/post.component';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.scss']
})
export class PostPreviewComponent {
  public author;
  public date: Date;
  @Input() public post: IPost;

  public getAuthor() {
    return content.contact.team.find(person => person.id === this.post.author);
  }
}
