import { SeoService } from './../services/seo.service';
import { Component, OnInit } from '@angular/core';
import { content } from '../content/content';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public home = content.home;

  constructor(private seo: SeoService) {}

  public ngOnInit() {
    this.seo.set({ title: this.home.title, description: this.home.description });
  }
}
