import { SafePipe } from './pipes/safe.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AppRoutingModule } from './app-routing.module';
import { CardComponent } from './card/card.component';
import { StoryComponent } from './story/story.component';
import { StoryCardComponent } from './story-card/story-card.component';
import { PiShorten } from './pipes/pi-shorten.pipe';
import { ContactComponent } from './contact/contact.component';
import { TeamComponent } from './contact/team/team.component';
import { AdressComponent } from './contact/adress/adress.component';
import { PersonComponent } from './contact/team/person/person.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { HostComponent } from './contact/host/host.component';
import { QuizComponent } from './quiz/quiz.component';
import { IotellSqueeze } from './pipes/iotell-squeeze.pipe';
import { HeaderComponent } from './header/header.component';
import { WhyComponent } from './why/why.component';
import { HomeComponent } from './home/home.component';
import { WhomComponent } from './whom/whom.component';
import { ChallengeComponent } from './challenge/challenge.component';
import { NewsComponent } from './news/news.component';
import { PostComponent } from './news/post/post.component';
import { PostPreviewComponent } from './news/post-preview/post-preview.component';
import { StoriesComponent } from './stories/stories.component';
import { OnetapbuyPromoComponent } from './onetapbuy-promo/onetapbuy-promo.component';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    CardComponent,
    StoryComponent,
    StoryCardComponent,
    PiShorten,
    ContactComponent,
    TeamComponent,
    AdressComponent,
    PersonComponent,
    ContactFormComponent,
    HostComponent,
    QuizComponent,
    IotellSqueeze,
    HeaderComponent,
    WhyComponent,
    WhomComponent,
    ChallengeComponent,
    NewsComponent,
    PostComponent,
    PostPreviewComponent,
    StoriesComponent,
    OnetapbuyPromoComponent,
    SafePipe
  ],
  imports: [BrowserModule, BrowserAnimationsModule, NgbModule, AppRoutingModule, Angulartics2Module.forRoot()],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
